/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { sharedRef } from '@vue-storefront/core';
import { useGeminiApi } from '~/composables';
import { useRoute, computed } from '@nuxtjs/composition-api';

const useTranslation = (translationsComposableKey = 'translations') => {
  const translations = sharedRef({}, translationsComposableKey);

  // Get the current route path and extract the locale from it
  const {
    value: { path },
  } = useRoute();
  const currentLocale = computed(() => path.split('/')[1] || 'it');

  const { getPayloadData } = useGeminiApi();

  const loadTranslations = async () => {
    try {
      const data = await getPayloadData('translations', '65b12f727a670092d0ca7a13');
      const translationsArray = data?.translations;
      const translationsObject = translationsArray.reduce((acc, { key, translation }) => {
        acc[key] = translation;
        return acc;
      }, {});
      translations.value = translationsObject;
    } catch (error) {
      // Log an error message if the translations block could not be loaded
      console.error(error);
      console.error('Could not get translations');
    }
  };

  // Define a function called $gt that returns the translation for a given key
  // If no locale is provided, the current locale parsed from the current path is used
  const $gt = (stringToTranslate: string) => translations?.value?.[stringToTranslate] || stringToTranslate;

  return {
    loadTranslations,
    translations,
    $gt,
    currentLocale,
  };
};

// Export the useTranslation function as the default export of this module
export default useTranslation;
