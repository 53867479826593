/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ref, useContext } from '@nuxtjs/composition-api';
import axios from 'axios';
import { isClient } from '~/helpers/check-environment';
import { InternalEndpoints } from '~/enums/internalEndpoints';

const useSettings = () => {
  const {
    app: {
      $config: { ssrMiddlewareUrl, middlewareUrl },
    },
  } = useContext();

  const getSetting = async (settingName: string) => {
    try {
      const { data } = await axios.get(`${isClient ? middlewareUrl : ssrMiddlewareUrl}${InternalEndpoints.GetPublicSettings}`);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      return data.find((setting) => setting.settingsOrderTypeIdentifier === settingName);
    } catch (e) {
      console.error(e);
    }
    return null;
  };

  return {
    getSetting,
  };
};

export default useSettings;
