/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { computed, ref, shallowRef } from '@nuxtjs/composition-api';
import { contentGetters, useContent } from '@gemini-vsf/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import seoHelpers from '~/helpers/seo/seoHelpers';
import { useGeminiApi } from '~/composables/';

const sanificateIncomingData = (obj) => {
  if (typeof obj !== 'object' || obj === null) return obj;

  return Object.entries(obj).reduce(
    (newObj, [key, value]) => {
      if (['id', 'blockName', 'blockType'].includes(key)) {
        return newObj; // skip these keys
      }
      const newKey = key === 'type' ? '@type' : key;
      newObj[newKey] = sanificateIncomingData(value);
      return newObj;
    },
    Array.isArray(obj) ? [] : {}
  );
};

const useHome = () => {
  const { locale } = useI18n();
  const { blocks, loadBlocks } = useContent(`HomeBlocks-${locale.includes('it') ? 'it' : 'en'}`);
  const { getAlternates, getCanonical } = seoHelpers();
  const { getPayloadData } = useGeminiApi();
  const structuredOrganizationData = ref();
  const structuredSiteLinkData = ref();

  const homeMeta = computed(() => ({
    title: 'Homepage - Colfert S.p.A.',
    meta: [
      {
        hid: 'og:description',
        property: 'og:description',
        content: `I prodotti Colfert in esclusiva per il serramentista - Colfert S.p.A`,
      },
      {
        hid: 'description',
        name: 'description',
        content: `I prodotti Colfert in esclusiva per il serramentista - Colfert S.p.A`,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: 'Homepage - Colfert S.p.A.',
      },
    ],
    link: [...getAlternates(), getCanonical()],
    script: [
      {
        json: {
          '@context': 'http://schema.org',
          ...structuredOrganizationData.value,
        },
        type: 'application/ld+json',
      },
      {
        json: {
          '@context': 'http://schema.org',
          ...structuredSiteLinkData.value,
        },
        type: 'application/ld+json',
      },
    ],
  }));

  const accountBlockSelector = shallowRef(`account-block-${locale.includes('it') ? 'it' : 'en'}`);
  const trainingBlockSelector = shallowRef(`training-${locale.includes('it') ? 'it' : 'en'}`);
  const newProductsSelector = shallowRef(`new-products-slider-${locale.includes('it') ? 'it' : 'en'}`);
  const bestSellerSliderSelector = shallowRef(`best-seller-slider-${locale.includes('it') ? 'it' : 'en'}`);
  const brandsBlockSelector = shallowRef(`brands-${locale.includes('it') ? 'it' : 'en'}`);
  const newsBlockSelector = shallowRef(`news-${locale.includes('it') ? 'it' : 'en'}`);

  const newsBlockContent = ref();
  const accountBlockContent = ref();
  const trainingBlockContent = ref();
  const newProductsBlockContent = ref();
  const bestSellerSliderContent = ref();
  const brandsBlockContent = ref();

  const recentProducts = ref();

  const loadHomeBlocks = async () => {
    await loadBlocks({
      identifiers: [
        accountBlockSelector.value,
        trainingBlockSelector.value,
        newProductsSelector.value,
        bestSellerSliderSelector.value,
        brandsBlockSelector.value,
        newsBlockSelector.value,
      ],
    });
    try {
      const blockData = contentGetters.getCmsBlockContent(blocks.value);
      accountBlockContent.value = blockData[accountBlockSelector.value];
      const trainingBlock =
        blockData[trainingBlockSelector.value] && blockData[trainingBlockSelector.value].active && blockData[trainingBlockSelector.value].data;
      trainingBlockContent.value = trainingBlock;
      newProductsBlockContent.value = blockData[newProductsSelector.value]?.data;
      bestSellerSliderContent.value = blockData[bestSellerSliderSelector.value]?.data;
      const brandsBlock =
        blockData[brandsBlockSelector.value] && blockData[brandsBlockSelector.value].active && blockData[brandsBlockSelector.value].data;
      // remove single brands that are set to inactive
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      brandsBlock.brands = brandsBlock?.brands?.filter((brand) => !!brand?.active);
      brandsBlockContent.value = brandsBlock;
      const newsBlock =
        blockData[newsBlockSelector.value] &&
        blockData[newsBlockSelector.value].active &&
        blockData[newsBlockSelector.value].data?.items?.length > 0 &&
        blockData[newsBlockSelector.value].data;
      newsBlockContent.value = newsBlock;
    } catch (e) {
      console.error('Unable to fetch block content.', e);
    }
  };

  const loadStructuredData = async () => {
    try {
      const organizationData = await getPayloadData('structured-data', '65ba472670248af8275b9c5a');
      const siteLinkData = await getPayloadData('structured-data', '65ba4d0170248af8275ba56f');
      structuredOrganizationData.value = sanificateIncomingData(organizationData.data[0]);
      structuredSiteLinkData.value = sanificateIncomingData(siteLinkData.data[0]);
    } catch (error) {
      // Log an error message if the translations block could not be loaded
      console.error(error);
      console.error('Could not get structured data', error);
    }
  };

  const loadRecentProducts = () => {
    recentProducts.value = JSON.parse(localStorage.getItem('recentProducts'));
  };

  return {
    loadRecentProducts,
    loadHomeBlocks,
    loadStructuredData,
    accountBlockContent,
    trainingBlockContent,
    newProductsBlockContent,
    bestSellerSliderContent,
    brandsBlockContent,
    newsBlockContent,
    homeMeta,
  };
};

export default useHome;
